import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Noto_Sans_JP\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-noto_sans_jp\",\"display\":\"swap\"}],\"variableName\":\"notoSansJP\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBarProvider","ProgressBar"] */ "/app/node_modules/react-transition-progress/dist/es/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageModal"] */ "/app/src/blocks/shared/modal/language-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryParamsModal"] */ "/app/src/blocks/shared/modal/query-params-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeftNavigation"] */ "/app/src/components/layout/pc/left-navigation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatadogInit"] */ "/app/src/lib/datadog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientGrowthBook"] */ "/app/src/lib/growth-book/client-growth-book.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/providers/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/app/src/providers/snackbar-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VirtualizerScrollRestorationProvider"] */ "/app/src/providers/virtualizer-scroll-restoration-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithApolloProvider"] */ "/app/src/providers/with-apollo-provider.tsx");
