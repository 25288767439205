import { from } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
} from '@apollo/experimental-nextjs-app-support';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { ModalContentType } from '@/components/layout/modals/types';
import {
  responseLink,
  clientHttpLink,
  authLink,
  globalErrorLink,
  payloadErrorLink,
} from '@/lib/apollo/link';
import { relayStylePaginationForChannelContents } from '@/lib/apollo/utils/relay-style-pagination-for-channel-contents';

const client = (
  showDialog: (message: ModalContentType) => void,
  cookieHeader: string,
  t: ReturnType<typeof useTranslations>,
) => {
  return new ApolloClient({
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
    },
    connectToDevTools: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'prd',
    cache: new InMemoryCache({
      possibleTypes: {
        ChannelPost: ['FanPost', 'ArtistPost'],
      },
      typePolicies: {
        Artist: {
          fields: {
            events: relayStylePagination(['statuses']),
            performanceChannels: relayStylePagination(['postable', 'actionable']),
          },
        },
        Me: {
          fields: {
            eventBadges: relayStylePagination(['artistID', 'sortOrder', 'isRead', 'first']),
            followingArtists: relayStylePagination(['sortOrder']),
            ticketSummaries: relayStylePagination(['sortOrder', 'statuses']),
            myNotifications: relayStylePagination(['isImportant', 'sortOrder']),
            paymentOrders: relayStylePagination(['sortOrder']),
            actionablePerformanceChannels: relayStylePagination(['sortOrder']),
            actionablePerformanceChannelsAsArtistOfficial: relayStylePagination(['sortOrder']),
            superMessageBadges: relayStylePagination(['artistID', 'sortOrder', 'first']),
          },
        },
        PaymentRelatedItem: {
          merge: true,
        },
        PerformanceInfo: {
          merge: true,
        },
        EventPeriod: {
          merge: true,
        },
        Performance: {
          fields: {
            performanceChannels: relayStylePagination(['sortOrder']),
          },
        },
        PerformanceChannel: {
          fields: {
            contents: relayStylePaginationForChannelContents(['sortOrder']),
          },
        },
        ChannelPost: {
          merge: true,
          keyFields: ['ulid'],
        },
        FanPost: {
          merge: true,
        },
        ArtistPost: {
          merge: true,
        },
        ChannelPermission: {
          merge: true,
        },
        Charge: {
          merge: true,
        },
        TicketSaleRequiredToken: {
          merge: true,
        },
        ApplyToalLimit: {
          merge: true,
        },
        SuperMessageInfo: {
          merge: true,
        },
        SuperMessageMasterAsset: {
          merge: true,
        },
        TicketSaleStockInfo: {
          merge: true,
        },
        TicketSalePriceDetailBase: {
          merge: true,
        },
        TicketSalePriceCalculated: {
          merge: true,
        },
        TicketSaleQuestion: {
          merge: true,
        },
        TicketLotteryApplicationItem: {
          keyFields: ['ticketApplicationID'],
        },
        TicketLotteryApplication: {
          keyFields: ['ticketApplicationID'],
        },
        TicketSummary: {
          keyFields: ['ticketApplicationID'],
        },
        Query: {
          fields: {
            pickupArtists: relayStylePagination(),
            platformNotifications: relayStylePagination(['isImportant', 'sortOrder']),
          },
        },
      },
    }),
    link: from([
      authLink(cookieHeader),
      globalErrorLink(showDialog, t),
      payloadErrorLink(showDialog, t),
      responseLink,
      clientHttpLink,
    ]),
  });
};

export const ApolloWrapper = ({
  showDialog,
  children,
  cookieHeader,
}: {
  showDialog: (message: ModalContentType) => void;
  children: ReactNode;
  cookieHeader: string;
}) => {
  const t = useTranslations();

  // MEMO:ApolloWrapperが再レンダリングされる度にcacheをリセットしないようにmemo化
  const memoizedClient = useMemo(() => {
    return () => client(showDialog, cookieHeader, t);
  }, [cookieHeader]);

  return <ApolloNextAppProvider makeClient={memoizedClient}>{children}</ApolloNextAppProvider>;
};
