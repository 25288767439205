'use client';

import type { GrowthBookPayload } from '@growthbook/growthbook';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

export function ClientGrowthBook({
  payload,
  visitorId,
  children,
}: PropsWithChildren<{ payload: GrowthBookPayload; visitorId?: string }>) {
  const gb = useMemo(() => {
    const instance = new GrowthBook({
      apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
      clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    });
    instance.initSync({
      payload,
      streaming: false,
    });
    instance.setAttributes({});
    return instance;
  }, [payload, visitorId]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
}
