'use client';

import { datadogRum } from '@datadog/browser-rum';
import { allowedTracingUrls } from '@/utils/allowed-tracing-urls';

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DD_APP_ID,
  clientToken: process.env.NEXT_PUBLIC_DD_TOKEN,
  site: 'ap1.datadoghq.com',
  service: 'klew-fan-frontend',
  env: process.env.NEXT_PUBLIC_ENVIRONMENT,
  version: process.env.NEXT_PUBLIC_VERSION_OR_COMMIT_HASH,
  sessionSampleRate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'prd' ? 10 : 100,
  sessionReplaySampleRate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'prd' ? 5 : 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls,
});

export function DatadogInit() {
  return null;
}
