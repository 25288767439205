import { ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import type { useTranslations } from 'next-intl';
import type { FanExtensionErrorCode } from '@/__generated__/graphql';
import type { ModalContentType } from '@/components/layout/modals/types';
import { ERROR_MAP } from '@/lib/apollo/error-map';
import { EXCLUDED_MUTATION_OPERATION_NAMES } from '@/lib/apollo/excluded-operation-name';
import { PAYLOAD_ERROR_MAP } from '@/lib/apollo/payload-error-map';
import { ngWordErrorDialog } from '@/lib/apollo/utils/ng-word-error-dialog';
import { findErrorOrSuccessType, isErrorType } from '@/utils/find-error-or-success-type';

export const clientHttpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_ORIGIN}/query`,
  credentials: 'include',
  fetchOptions: {
    // Cacheを利用しない
    cache: 'no-store',
  },
});

export const serverHttpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_ORIGIN}/query`,
  credentials: 'include',
  fetchOptions: {
    /// Cacheを利用しない
    cache: 'no-store',
  },
});

export const payloadErrorLink = (
  showDialog: (message: ModalContentType) => void,
  t: ReturnType<typeof useTranslations>,
) =>
  new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      if (response.data) {
        // 除外リストに含まれるoperationNameの場合は共通エラーハンドリングをスキップ
        if (EXCLUDED_MUTATION_OPERATION_NAMES.includes(operation.operationName)) {
          return response;
        }
        const result = findErrorOrSuccessType(response.data);
        if (!result) return response;
        if (isErrorType(result)) {
          if (PAYLOAD_ERROR_MAP(result, t)) {
            // Ngワード判定
            if (result.__typename === 'ErrorValidationFailed') {
              ngWordErrorDialog(result, showDialog, t);
            } else {
              showDialog(PAYLOAD_ERROR_MAP(result, t));
            }
          } else {
            showDialog(ERROR_MAP('INTERNAL', t));
          }
        }
      }

      return response;
    });
  });

export const globalErrorLink = (
  showDialog: (message: ModalContentType) => void,
  t: ReturnType<typeof useTranslations>,
) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const operationDefinition = operation.query.definitions?.[0];
    // Pagination実行時は共通エラーハンドリングをスキップ
    if ('after' in operation.variables || 'before' in operation.variables) {
      return;
    }

    const isServer = typeof window === 'undefined';

    graphQLErrors?.forEach((i) => {
      switch (i.extensions?.code as FanExtensionErrorCode) {
        case 'Internal':
          showDialog(ERROR_MAP('INTERNAL', t));
          break;
        case 'NotFound':
          if (operationDefinition && operationDefinition.kind === 'OperationDefinition') {
            const operationType = operationDefinition.operation; // 'query', 'mutation', 'subscription'のいずれか
            if (operationType === 'mutation') {
              showDialog(ERROR_MAP('UNAUTHORIZED', t));
            }
          }
          break;
        case 'OpenseaInternal':
          showDialog(ERROR_MAP('OPEN_SEA_INTERNAL', t));
          break;
        case 'Unauthorized':
          if (operationDefinition && operationDefinition.kind === 'OperationDefinition') {
            const operationType = operationDefinition.operation; // 'query', 'mutation', 'subscription'のいずれか
            if (operationType === 'mutation') {
              showDialog(ERROR_MAP('UNAUTHORIZED', t));
            }
          }
          break;
        default:
          if (!isServer) {
            showDialog(ERROR_MAP('INTERNAL', t));
          }
      }
    });
    if (networkError && !isServer) {
      showDialog(ERROR_MAP('NETWORK', t));
    }
  });

export const serverErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export const responseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((res) => {
    if (typeof window === 'undefined') {
      console.log(`${operation.operationName}`);
    }
    return res;
  });
});

export const authLink = (cookieHeader?: string) => {
  return setContext(async (_, { headers }) => {
    let authHeader = {};

    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' &&
      process.env.NEXT_PUBLIC_ENABLE_IAM === 'true'
    ) {
      try {
        const data = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/frontend-api/google-auth`, {
          cache: 'no-store',
        });
        authHeader = await data.json();
      } catch (e) {
        console.log('認証エラー', e);
        throw e;
      }
    }

    if (
      typeof window === 'undefined' &&
      process.env.NEXT_PUBLIC_ENABLE_IAM === 'true' &&
      process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local'
    ) {
      try {
        const targetAudience = process.env.NEXT_PUBLIC_TARGET_AUDIENCE;
        const { GoogleAuth } = await import('google-auth-library');
        const auth = new GoogleAuth({
          keyFile: process.env.GOOGLE_APPLICATION_CREDENTIALS,
        });
        const client = await auth.getIdTokenClient(targetAudience);
        const headers = await client.getRequestHeaders();
        authHeader = headers;
      } catch (e) {
        console.log('認証エラー', e);
        throw e;
      }
    }

    return {
      headers: {
        ...headers,
        ...authHeader,
        cookie: cookieHeader,
      },
    };
  });
};
